<template>
  <div id="wrongquestion">
    <div class="top-title">
      <div>错题集</div>
    </div>
    <div class="wrong-list">
      <div class="wrong-top">
        <div class="new">最新</div>
        <el-button @click="emptyclick" v-if="total !== 0">一键清空</el-button>
      </div>
      <div class="notYet" v-if="total == 0">暂无错题</div>
      <div class="wrongtext" v-for="(item, index) in wronglist" :key="index">
        <div class="wrong-main">
          <div>
            <div>
              <span v-if="!item.read"> 【{{ item.question.types }}】</span>
              <span v-else> 【{{ item.read.questions[0].types }}】</span>
            </div>
            <div class="wrong-text">
              <p
                v-if="!item.read"
                class="questionStem"
                v-html="item.question.stem"
              ></p>
              <p
                v-else
                class="questionStem"
                v-html="item.read.questions[0].stem"
              ></p>
            </div>
          </div>
          <div class="right-msg">
            <p class="delet" @click="singeldelet(item)">删除</p>
            <p class="wrongbutton" @click="clickSend('错题集', index)">
              错题回顾
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  head: {
    title: "错题集 - 西知题库",
  },
  data() {
    return {
      wronglist: [],
      total: 0,
    };
  },
  methods: {
    refirsh() {
      let _this = this;
      let obj = {
        page: 1,
        limit: 99999,
      };
      _this.$api.errorlist(obj).then((res) => {
        _this.wronglist = res.data.data;
        console.log(res.data.total);
        _this.total = res.data.total;
        this.loading.close();
      });
    },
    //一键清空
    emptyclick() {
      this.$confirm("此操作将清空所有记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          console.log(111);
          this.$api.emptyerrorstate().then((res) => {
            console.log(res);
            this.$message({
              type: "success",
              message: "成功清空!",
            });
            this.refirsh();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消清空",
          });
        });
    },
    // 单个删除
    singeldelet(item) {
      this.$confirm("此操作将删除此错题, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          console.log(1111);
          let obj = {
            question_id: item.question_id,
          };
          console.log(obj);
          this.$api.emptyerrorstate(obj).then((res) => {
            console.log(res);
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.refirsh();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    clickSend(val, index) {
      let _this = this;
      console.log(val, index);
      _this.$router.push({
        path: "/Index/Pratice",
        query: {
          val: val,
          index: index,
        },
      });
    },
  },
  created() {
    this.loading = this.$loading({
      lock: true,
      text: "Loading",
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 0.7)",
    });
    this.refirsh();
  },
};
</script>

<style lang="less" scoped>
@color: #df3833;
#wrongquestion {
  padding-top: 50px;
  min-height: 835px;
  width: 1180px;
  margin: auto;
  .top-title {
    padding: 20px;
    background: #fff;
    margin-bottom: 20px;
    border-radius: 4px;
  }
  .wrong-list {
    width: 100%;
    border-radius: 4px;
    margin: auto;
    background: #fff;
    padding: 30px 20px;
    .notYet {
      font-size: 14px;
      color: #333;
      margin-top: 20px;
    }
    .wrong-top {
      display: flex;
      justify-content: space-between;
      .new {
        border-bottom: 2px solid @color;
        width: 50px;
        padding-bottom: 10px;
        padding-left: 10px;
        color: @color;
      }
      .el-button {
        padding: 5px 10px;
      }
    }

    .wrongtext {
      border: 1px solid #ddd;
      border-radius: 8px;
      margin-top: 20px;
      .wrong-main {
        display: flex;
        justify-content: space-between;

        > div {
          padding: 20px;

          // width: 900px;
          // min-height: 100px;
          overflow: hidden;
          .wrong-text {
            margin-top: 20px;

            max-height: 100px;

            p {
              width: 850px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
            .questionStem{
              /deep/p{
                font-weight: normal;
                span{
                  font-weight: normal !important;
                }
              }
            }
          }
        }
        .right-msg {
          border-left: 1px solid #ddd;
          padding-top: 50px;
          display: flex;
          // justify-content: space-around;
          p {
            height: 34px;
            line-height: 34px;
            text-align: center;
            cursor: pointer;
            border-radius: 4px;
            font-size: 14px;
          }
          .delet {
            height: 32px;
            line-height: 32px;
            width: 60px;
            background: #df3833;
            color: #fff;
          }
          .wrongbutton {
            border: 1px solid #dfdfdf;
            background: #f5f5f5;
            color: #333;
            margin-left: 20px;
            width: 100px;

            &:hover {
              background: @color;
              border-color: @color;
              color: #fff;
            }
          }
        }
      }
    }
  }
}
</style>